<template>
  <b-form-group
    label="Model"
    label-for="id_com_model"
  >
    <v-select
      id="id_com_model"
      v-model="filterData.id_com_model"
      placeholder="Model"
      :options="models"
      label="title"
      :reduce="model => model.id"
      :disabled="!filterData.id_com_brand"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
  },
  watch: {
    filterData: {
      deep: true,
      handler(val) {
        if (val.id_com_brand) {
          this.getModels()
        }
      },
    },
  },
  methods: {
    getModels() {
      const params = {
        select: [
          'com_model.id AS id',
          'com_model.title AS title',
        ],
        where: {
          'com_model.id_com_brand': this.filterData.id_com_brand,
        },
      }
      this.$store.dispatch('models/modelsList', params)
    },
    resetModel() {
      this.filterData.id_com_model = null
    },
  },
}
</script>

<style scoped>

</style>
