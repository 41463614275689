<template>
  <b-form-group
    label="Test Sürüşü"
    label-for="testdrive"
  >
    <v-select
      id="testdrive"
      v-model="filterData.testdrive"
      placeholder="Test Sürüşü"
      :options="testDrives"
      label="title"
      :reduce="testDrive => testDrive.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'TestDrives',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    testDrives() {
      return [{
        id: '1',
        title: 'Yapıldı',
      },
      {
        id: '0',
        title: 'Yapılmadı',
      }]
    },
  },
}
</script>

<style scoped>

</style>
